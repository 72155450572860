export const formatName = (firstName = "", lastName = "") => {
  return `${firstName ? firstName : ""} ${lastName ? lastName : ""}`.trim();
};

export const isNumber = (num) => {
  return !isNaN(Number(num));
};

export const capitalize = (string = "") => {
  return string
    ? `${string[0]?.toString().toUpperCase()}${string?.substring(1).toString().toLowerCase()}`
    : "";
};

export const pluralize = (count, singular, plural) => {
  return count >= 2 ? plural : singular;
};

export const formatNumberToOrdinal = (num = 0) => {
  let lastDigit = Number(num.toString().charAt(num.toString().length - 1));
  if ([11, 12, 13].includes(num)) {
    lastDigit = null;
  }
  switch (lastDigit) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

export const isEmailValid = (email) => {
  const regex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/);
  return regex.test(email.toLowerCase().trim());
};

export const isAlphaNumeric = (text) => {
  const regex = new RegExp(/[^A-Za-z0-9]/);

  return !regex.test(text);
};

export const isAlphaNumericWithSymbols = (text) => {
  const regex = new RegExp(/[^A-Za-z0-9Ññ.,'-\s-]/);
  return !regex.test(text);
};

export const isNameValid = (text) => {
  const regex = new RegExp(/[^A-Za-z0-9Ññ.-\s-]/);
  return !regex.test(text);
};

export const isNumeric = (text) => {
  const regex = new RegExp(/[^0-9]/);
  return !regex.test(text);
};

export const isMobileNumberValid = (mobile) => {
  const regex = new RegExp(/^(09|639)\d{9}$/);
  return regex.test(mobile.trim());
};

export const isMobileNumberValidv2 = (mobile) => {
  if (mobile.substring(0, 1) !== "9") {
    return false;
  }
  const regex = new RegExp(/^[9]+?\d{9}$/);
  return regex.test(mobile.trim());
};

export const isMobileNumberStrictValid = (mobile = "") => {
  if (mobile.substring(0, 4) !== "+639") {
    return false;
  }
  const num = mobile.substring(4);
  return isNumeric(num) && mobile.length === 13;
};

export const isMobileNumberValid11 = (mobile = "") => {
  if (mobile.substring(0, 2) !== "09") {
    return false;
  }
  return isNumeric(mobile) && mobile.toString().length === 11;
};

export const isPhNumberFormat = (mobile) => {
  const regex = new RegExp(/^639/);
  return regex.test(mobile.trim());
};

export const isDriverLicenseIdValid = (mobile) => {
  const regex = new RegExp(/^([A-Z]{1})+?\d{10}$/);
  return regex.test(mobile.trim());
};

export const formatAmount = (num, prefix = "₱") => {
  return `${prefix} ${Number(num).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

export const formatDriverName = (fn, ln) => {
  let name = `${capitalize(fn ? fn : "")} ${capitalize(ln ? ln : "")}`.trim();
  if (name === "") {
    name = null;
  }
  return name;
};

export const formatPriceMovementPerLiter = (num) => {
  let sign = "";
  switch (num) {
    case 0:
      sign = "";
      break;
    default:
      sign = Math.sign(num) > 0 ? "+" : "-";
  }

  return `${sign}₱ ${Number(Math.abs(num)).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}/L`;
};

export const isPasswordValid = (password) => {
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const number = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
  return regex.test(password) || number.test(password);
};
