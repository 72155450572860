const env = {
  ID: "prod",
  API_URL: "https://api.locq.com",
  WEB_SOCKET_URL: "wss://pd3wolnhxa.execute-api.ap-southeast-1.amazonaws.com/prod",
  PAYMAYA_API_URL: "https://pg.paymaya.com/payments/v1/payment-tokens",
  PAYMAYA_PUBLIC_KEY: "pk-iT1tdg41ifBkwNRDpVUO2LdnezgK7ipcfpsdQ25AaK6",
  SEGMENT_WRITE_KEY: "NaXUTEbWcoWKKZGbus5VNw6xnKnM3jkt",
  GOOGLE_MAP_API_KEY: "AIzaSyAj0drQ31XFH9uMGD85hAyf3GXQnpmieOY",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyB7-3Rjep658GjfGxO9Fn2nmRYT2KaawEw",
    authDomain: "pricelocq-business-prod.firebaseapp.com",
    databaseURL:
      "https://pricelocq-business-prod-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "pricelocq-business-prod",
    storageBucket: "pricelocq-business-prod.appspot.com",
    messagingSenderId: "518328425697",
    appId: "1:518328425697:web:d19951f5a89443b7f12ba0",
  },
};

export default env;
